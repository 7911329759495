<template>
<div class="m-4">
  <p>Emulators require to simulate scheduler</p>
  <p v-if="startTime">
    {{ formattedTime }}
  </p>
  <button class="btn btn-primary" @click="startScheduler(5000)" :disabled="!useEmulators" v-if="!handler">Start 5s scheduler</button>
  <button class="btn btn-primary ml-2" @click="startScheduler(6000)" :disabled="!useEmulators" v-if="!handler">Start minute scheduler</button>
  <button class="btn btn-warning" @click="stopScheduler()" v-else>Stop scheduler</button>
</div>
</template>

<script>
import { useEmulators } from "@/services/firebase";
import { emulatorScheduledEventUpdate } from "@/services/functions";
import { getLog } from "@/services/log";
import { formatTimer, epoch } from "@/services/utils";
let log = getLog('test');

export default {
  data() {
    return {
      useEmulators,
      handler: null,
      startTime: 0,
      formattedTime: "",
    }
  },
  beforeDestroy() {
    if (this.handler)
      this.stopScheduler();
  },
  methods: {
    startScheduler(interval) {
      let fun = () => {
        log.log("emulatorScheduledEventUpdate");
        this.startTime = Math.floor(Date.now() / 1000);
        emulatorScheduledEventUpdate();
      };
      fun();
      this.handler = setInterval(fun, interval)
      setInterval(() => {
        this.formattedTime = formatTimer(this.startTime, interval/60000, epoch());
      }, 1000);
    },
    stopScheduler() {
      clearInterval(this.handler);
      this.handler = null;
    }
  }
}
</script>

<style>

</style>